<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item>活动分类管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <router-link to="/dashboard/add_activity">
                    <el-button type="primary" icon="plus" size="small">添加分类</el-button>
                </router-link>
            </div>
        </div>
        <div class="content-main">
            
            <div class="form-table-box">
                <el-table :data="tableData" style="width: 100%" stripe>
                    <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->
                    <el-table-column prop="activity_name" label="活动名称"></el-table-column>
                    <el-table-column prop="bind_goods.length" label="包含商品个数"></el-table-column>
                    <el-table-column prop="title_color" label="主题颜色">
                        <template scope="scope">
                            <el-input readonly="readonly" class="sort-width" controls-position="right" v-model="scope.row.title_color" placeholder="主题颜色"></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column prop="sort_order" label="展示排序" width="140" sortable>
                        <template scope="scope">
                            <el-input-number class="sort-width" size="mini" :min="1" :max="100" controls-position="right" v-model="scope.row.sort_order" placeholder="排序" @blur="submitSort(scope.$index, scope.row)" ></el-input-number>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作">
                        <template scope="scope">
                            <el-button size="small" @click="handleRowEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="small" plain type="danger" @click="handleRowDelete(scope.$index, scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page-box">
                <el-pagination @current-change="handlePageChange" :current-page="page" :page-size="size"
                               layout="total, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                page: 0,
				size:10,
                total: 0,
                tableData: [],
                pIndex: 0,
                num: 0,
            }
        },
        methods: {
            handleClick(tab, event) {
                let pindex = tab._data.index;
				this.page = 1;
                this.getActivityList();
                this.pIndex = 1;
            },
            handlePageChange(val) {
                this.page = val;
                let nIndex = this.pIndex;
                if (nIndex == 0) {
                    this.getList();
                }
            },
            handleRowEdit(index, row) {
                localStorage.setItem('select_activity_id',row._id);
                this.$router.push({name: 'add_activity', query: {id: row._id}});
            },
            handleRowDelete(index, row) {
                this.$confirm('确定要删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                let that = this;
                console.log("row._id is " + row._id);
                that.axios.post('home/deleteActivity', {id: row._id}).then((response) => {
                    if (response.data.code > 0) {
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        that.getActivityList();
                    }
                })
                }).catch(() => {

                });
            },
            submitSort(index, row){
                var that = this;
                this.axios.post('home/updateActivitySort', { id: row._id,sort_order:row.sort_order }).then((response) => {
               
                   if(response.data.code>0)
                   {

                   }
                   else
                   {
                        this.$message.error('更新排序错误!');
                   }
                })
            },
            onSubmitFilter() {
                this.page = 1;
                this.getList();
            },
            clear(){
                this.axios.get('home/getActivityList', {
                    params: {
                        page: this.page,
						size: this.size
                    }
                }).then((response) => {
                    this.tableData = response.data.data
                    // this.page = response.data.data.currentPage
                    // this.total = response.data.data.count
                })
            },
            getActivityList() {
                this.axios.get('home/getActivityList', {
                    params: {
                        page: this.page,
						size: this.size
                    }
                }).then((response) => {

                    console.log("获取视频列表。。。");
                    console.log(response.data);
                    this.tableData = response.data.data
                    //  this.page = response.data.data.currentPage
                    // this.total = response.data.data.count
                })
            },
        },
        components: {},
        mounted() {
            localStorage.removeItem('select_activity_id');
            this.getActivityList();
        }
    }

</script>

<style scoped>
    .sort-width{
        width: 90px;
    }
    .right{
        float: right;
    }
    .form-inline {
        margin-top: 2px;
        height: 40px;
        margin-right: 20px;
    }

    .block {
        margin-bottom: 10px;
        height:42px;
        display: flex;
        align-items: center;
        justify-content:space-between;
    }

    .active {
        border-color: #ff4949;
        color: #ff4949;
    }

    .marginRight{
        margin-right: 20px;
    }
</style>
